import React from 'react'
import styled from 'styled-components'

function clean(item) {
  return item.replace(/[^0-9a-z]/gi, '')
}

function Accordion(props) {
  return <div>{props.children}</div>
}
function Toggle(props) {
  return (
    <StyleWrapper label={clean(props.label)}>
      <Input id={clean(props.label)} type="checkbox" name="toggle" />
      <Label
        color={props.color}
        hoverColor={props.hoverColor}
        htmlFor={clean(props.label)}
      >
        <H2 as={props.as} id={`${clean(props.label)}header`}>
          {props.label}
        </H2>
      </Label>
      <Section id={`${clean(props.label)}content`}>{props.children}</Section>
    </StyleWrapper>
  )
}

const Input = styled.input`
  position: absolute;
  opacity: 0;

  &:focus + label {
    color: black;
    background-color: wheat;
  }
`
const Label = styled.label`
  position: relative;
  display: block;
  cursor: pointer;
  &:hover {
    color: #07588a;
  }
`
const H2 = styled.h2`
  margin: 0.75rem 0;
  &:before {
    float: left;
    content: '+';
    margin-right: 0.5em;
  }
`
const Section = styled.section`
  padding-left: 1em;
  margin-left: .5rem;
  border-left: 1px solid black;
  height: 0;
  transition: 0.1s all;
  overflow: hidden;
  border-bottom: 1px solid black;
}
`
const StyleWrapper = styled.div`
  ${props => `#${clean(props.label)}:checked ~ #${clean(props.label)}content {
  padding-top: .5em;
  height: auto;
}`}
  &:last-child {
  margin-bottom: 2em;
`

export { Accordion, Toggle }
